import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokTextCardType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokTextCard: FC<
  StoryblokComponentProps<StoryblokTextCardType>
> = ({ blok }) => {
  const { background, content } = blok

  return (
    <div
      {...storyblokEditable(blok)}
      className={clsx(
        background === 'Background-Light-Brand-Medium' &&
          'bg-light-brand-medium',
        'p-4',
        'rounded-2xl',
      )}
    >
      <StoryblokComponentsRenderer bloks={content} />
    </div>
  )
}
